import React from 'react';
import { createRoot } from "react-dom/client";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeContextWrapper from './theme/ThemeWrapper';
import PiwikPro from '@piwikpro/react-piwik-pro';

// Initialisation de Piwik Pro
PiwikPro.initialize('7a920e8c-1fcb-4c94-a103-e722c9f8366f', 'https://beely.piwik.pro');

const root = document.getElementById('root');
createRoot(root).render(
  <ThemeContextWrapper>
    <App />
  </ThemeContextWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
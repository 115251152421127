import React from "react";
import '../scss/LoaderPages.scss';
import MiniLogo from '../assets/logos/logo-loader-pages-beely.webp';

export default function LoaderPages() {
    return (
        <section className="loaderPages">
            <img width='100px' src={MiniLogo} className='imageBounceIn' alt='Logo de chargement Beely' />
        </section>
    )
}
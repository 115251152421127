import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './App.scss';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ThemesProvider from './contexts/ThemesProvider';
import BlogProvider from './contexts/BlogProvider';
import ScrollToTop from './components/ScrollToTop';
import LoaderPages from './components/LoaderPages';
import ArrowUp from './components/ArrowUp';
const Home = lazy(() => import('./pages/Home'));
const Tarifs = lazy(() => import('./pages/Tarifs'));
const OnePageWeb = lazy(() => import('./pages/OnePage'));
const WebsiteWeb = lazy(() => import('./pages/Website'));
const ShopWeb = lazy(() => import('./pages/Shop'));
const HostingPage = lazy(() => import('./pages/Hosting').then(module => ({ default: module.HostingPage })));
const SeoPage = lazy(() => import('./pages/Seo').then(module => ({ default: module.SeoPage })));
const LogoPage = lazy(() => import('./pages/Logo').then(module => ({ default: module.LogoPage })));
const ChartePage = lazy(() => import('./pages/Charte').then(module => ({ default: module.ChartePage })));
const ThemesBeely = lazy(() => import('./pages/Themes'));
const Faq = lazy(() => import('./pages/Faq'));
const Glossary = lazy(() => import('./pages/Glossary'));
const Ressources = lazy(() => import('./pages/Ressources'));
const FormulaireContact = lazy(() => import('./pages/FormulaireContact'));
const FormulaireCommande = lazy(() => import('./pages/FormulaireCommande'));
const FormulaireSupport = lazy(() => import('./pages/FormulaireSupport'));
const Blog = lazy(() => import('./pages/Blog'));
const SingleBlog = lazy(() => import('./components/Blog/SingleBlog'));
const CategoryArchive = lazy(() => import('./components/Blog/CategoryArchive'));
const SearchResultsBlog = lazy(() => import('./components/Blog/SearchResultsBlog'));
const Help = lazy(() => import('./pages/Help'));
const SingleHelp = lazy(() => import('./components/Help/SingleHelp'));
const SearchResultsHelp = lazy(() => import('./components/Help/SearchResultsHelp'));
const CategoryArchiveThemes = lazy(() => import('./components/ThemesBeely/CategoryArchiveThemes'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./components/Dashboard/Profile'));
const Order = lazy(() => import('./components/Dashboard/Order'));
const Website = lazy(() => import('./components/Dashboard/Website/Website'));
const Shop = lazy(() => import('./components/Dashboard/Shop/Shop'));
const Support = lazy(() => import('./components/Dashboard/Support'));
const MySites = lazy(() => import('./components/Dashboard/MySites'));
const Login = lazy(() => import('./components/Account/LogIn'));
const Register = lazy(() => import('./components/Account/Register'));
const ResetPassword = lazy(() => import('./components/Account/ResetPassword'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const PageCategoriesBlog = lazy(() => import('./components/Blog/PageCategoriesBlog'));
const MentionsLegales = lazy(() => import('./pages/MentionsLegales'));
const PolitiqueConfidentialite = lazy(() => import('./pages/PolitiqueConfidentialite'));
const Cgv = lazy(() => import('./pages/Cgv'));
const Cookies = lazy(() => import('./pages/Cookies'));
const MyServices = lazy(() => import('./components/Dashboard/MyServices'));
const OnePage = lazy(() => import('./components/Dashboard/OnePage/OnePage'));
const SeoService = lazy(() => import('./components/Dashboard/Services/Seo'));
const HebergementService = lazy(() => import('./components/Dashboard/Services/Hebergement'));
const LogoService = lazy(() => import('./components/Dashboard/Services/Logo'));
const CharteService = lazy(() => import('./components/Dashboard/Services/Charte'));
const SuccessWebsitePage = lazy(() => import('./components/Dashboard/SuccessWebsite'));
const SuccessServicePage = lazy(() => import('./components/Dashboard/SuccessService'));
const AbandonPage = lazy(() => import('./components/Dashboard/Abandon'));
const HebergementPageMensuel = lazy(() => import('./components/Dashboard/Services/OrderServices/HebergementOrder'));
const ChartePageUnique = lazy(() => import('./components/Dashboard/Services/OrderServices/CharteOrder'));
const LogoPageUnique = lazy(() => import('./components/Dashboard/Services/OrderServices/LogoOrder'));
const SeoPageUnique = lazy(() => import('./components/Dashboard/Services/OrderServices/SeoOrder'));
const Sitemap = lazy(() => import('./pages/Sitemap'));

function App() {

  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      link.href = '../favicon/dark/favicon.ico';
    } else {
      link.href = '../favicon/light/favicon.ico';
    }

    document.getElementsByTagName('head')[0].appendChild(link);
  }, []);

  return (
    <div className="App">
      <ThemesProvider>
        <BlogProvider>
          <Router>
            <ScrollToTop />
            <Suspense fallback={<LoaderPages />}>
              <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/tarifs' element={<Tarifs />}></Route>
                <Route path='/tarifs/site-internet-une-page' element={<OnePageWeb />}></Route>
                <Route path='/tarifs/site-internet-plusieurs-pages' element={<WebsiteWeb />}></Route>
                <Route path='/tarifs/site-internet-e-commerce' element={<ShopWeb />}></Route>
                <Route path='/tarifs/hebergement' element={<HostingPage />}></Route>
                <Route path='/tarifs/seo' element={<SeoPage />}></Route>
                <Route path='/tarifs/logo' element={<LogoPage />}></Route>
                <Route path='/tarifs/charte-graphique' element={<ChartePage />}></Route>
                <Route path='/themes' element={<ThemesBeely />}></Route>
                <Route path='/themes/categories' element={<ThemesBeely />}></Route>
                <Route path='/themes/categories/:categorySlug' element={<CategoryArchiveThemes />}></Route>
                <Route path='/blog' element={<Blog />}></Route>
                <Route path="/blog/categories" element={<PageCategoriesBlog />} />
                <Route path="/blog/categories/:categorySlug" element={<CategoryArchive />} />
                <Route path='/blog/recherche/:searchQuery' element={<SearchResultsBlog />}></Route>
                <Route path="/blog/:postSlug" element={<SingleBlog />} />
                <Route path='/ressources' element={<Ressources />}></Route>
                <Route path='/ressources/faq' element={<Faq />}></Route>
                <Route path='/ressources/glossaire' element={<Glossary />}></Route>
                <Route path='/help' element={<Help />}></Route>
                <Route path='/help/recherche/:searchQuery' element={<SearchResultsHelp />}></Route>
                <Route path="/help/:postSlug" element={<SingleHelp />} />
                <Route path='/ressources/mentions-legales' element={<MentionsLegales />}></Route>
                <Route path='/ressources/politique-confidentialite' element={<PolitiqueConfidentialite />}></Route>
                <Route path='/ressources/cgv' element={<Cgv />}></Route>
                <Route path='/ressources/cookies' element={<Cookies />}></Route>
                <Route path='/formulaires/contacter-beely' element={<FormulaireContact />}></Route>
                <Route path='/formulaires/demande-devis' element={<FormulaireCommande />}></Route>
                <Route path='/formulaires/demande-support' element={<FormulaireSupport />}></Route>
                <Route path='/sitemap' element={<Sitemap />}></Route>
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
            <ArrowUp />
          </Router>
        </BlogProvider>
      </ThemesProvider>
    </div>
  );
}



export default App;
import React, { useState, useEffect } from "react";

export const themes = {
  dark: "darkTheme",
  light: "lightTheme",
};

export const ThemeContext = React.createContext({
  theme: themes.dark,
  changeTheme: () => {},
});

export const ThemeProvider = (props) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || themes.dark
  );

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

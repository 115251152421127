import React, { useState, useEffect } from 'react';
import { ThemesContext } from './ThemesContext';

const ThemesProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const apiBaseUrl = 'https://headless.beely.dev/wp-json/wp/v2/';

  useEffect(() => {
    // Fetch all posts
    fetch(`${apiBaseUrl}posts`)
      .then(response => response.json())
      .then(data => {
        setPosts(data);
      })
      .catch(error => {
        console.error("Error fetching posts:", error);
      });

    // Fetch all categories with a higher per_page limit
    fetch(`${apiBaseUrl}nos-themes-sites?per_page=100`)
      .then(response => response.json())
      .then(data => {
        setCategories(data);
      })
      .catch(error => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  return (
    <ThemesContext.Provider value={{ posts, categories }}>
      {children}
    </ThemesContext.Provider>
  );
}

export default ThemesProvider;
import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from '../contexts/ThemeContext';

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || themes.light);

  function changeTheme(newTheme) {
    setTheme(newTheme);
  }

  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.body.classList.add('lightTheme');
        break;
      case themes.dark:
      default:
        document.body.classList.add('lightTheme');
        break;
    }
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
import React, { useState, useEffect } from 'react';
import { BlogContext } from './BlogContext';

const BlogProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const apiBaseUrl = 'https://headless.beely.dev/wp-json/wp/v2/';

  useEffect(() => {
    fetch(`${apiBaseUrl}posts`)
      .then(response => response.json())
      .then(data => {
        setPosts(data);
      })
      .catch(error => {
        // console.log(error);
      });

    fetch(`${apiBaseUrl}categories`)
      .then(response => response.json())
      .then(data => {
        setCategories(data);
      })
      .catch(error => {
        // console.log(error);
      });
  }, []);

  return (
    <BlogContext.Provider value={{ posts, categories }}>
      {children}
    </BlogContext.Provider>
  );
}

export default BlogProvider;
